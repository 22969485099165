<template>
  <div v-if="this.$store.state.creatingBusinessLoader">
    <div
      class="absolute top-1/2 left-1/2 z-50"
      style="height: auto; width: auto; transform: translate(-50%, -50%)"
    >
      <div class="loader_krssu"></div>
    </div>
  </div>

  <div
    v-else
    class="flex w-full h-full flex-col justify-center"
    style="padding-left: 88px; padding-right: 88px"
  >
    <div
      class="text-left text-sblue-600 text-3xl"
      style="font-family: Metropolis_semiBold"
    >
      {{ $t("setup_tile") }}
    </div>
    <div
      class="text-left text-sblue-300 mt-4"
      style="font-family: Metropolis_medium; font-size: 1.375rem"
    >
      {{ $t("setup_message") }}
    </div>

    <div class="w-full flex flex-col">
      <router-link v-for="step in steps" :key="step.id" :to="step.link">
        <HorizontalCard
          :text="$t(step.text)"
          :pic="step.pic"
          :svgSettings="{
            color: colors.mainBlue,
            width: 23.208,
            height: 23.208,
            key: step.svgSettings.key,
          }"
          :status="completed.includes(step.svgSettings.key)"
        />
      </router-link>
    </div>
    <div class="w-full flex justify-center flex-col align-middle mx-auto">
      <div
        class="
          text-center
          mx-auto
          flex
          justify-center
          align-middle
          items-center
          mt-10
          sm:mt-16
        "
      >
        <router-link to="/about-yourself">
          <div
            class="
              cursor-pointer
              text-xl text-sblue-400 text-center
              mx-auto
              flex
              justify-center
              align-middle
              items-center
              w-full
              min-w-full
            "
            style="line-height: 35px; border-radius: 10px; margin-right: 68px"
          >
            <div v-html="getSvg(colors.mainBlue, 18, 18, 'backArrow')"></div>
            <div style="margin-left: 19px">Back</div>
          </div>
        </router-link>
        <div
          v-if="
            completed.includes('step1') &&
            completed.includes('step2') &&
            completed.includes('step3')
          "
          class="
            font-thin
            cursor-pointer
            text-xl
            bg-sblue-400
            text-white text-center
            py-4
            px-12
            button_shadow
            rounded-lg
          "
          @click="handleSubmit"
        >
          Create Business
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalCard from "@/components/HorizontalCard.vue";

import { getSvg } from "../utils/getSvg";
import { getColors } from "../utils/getColors";
export default {
  name: "MainSteps",
  title: "Set up steps",
  components: {
    HorizontalCard,
  },
  data() {
    return {
      colors: {},
      steps: [
        {
          id: 2,
          link: "/rules-setup",
          text: "third_step_message",
          pic: "reward-settings",
          svgSettings: {
            key: "step3",
          },
        },
        {
          id: 1,
          link: "/referral-program-setup",
          text: "second_step_message",
          pic: "referral-settings",
          svgSettings: {
            key: "step2",
          },
        },
        {
          id: 3,
          link: "/widget-theme-selection",
          text: "first_step_message",
          pic: "branding-settings",
          svgSettings: {
            key: "step1",
          },
        },
      ],
    };
  },
  computed: {
    completed() {
      return this.$store.state.completedSteps;
    },
  },
  mounted() {
    this.colors = getColors;
  },
  methods: {
    getSvg: getSvg,

    getIntegrationId(platformId) {
      if (platformId == 13) {
        return 5;
      } else if (platformId == 14) {
        return 6;
      } else if (platformId == 15) {
        return 7;
      } else if (platformId == 16) {
        return 8;
      } else if (platformId == 18) {
        return 10;
      }
    },

    async handleSubmit() {
      try {
        this.$store.commit("setBusinessLoader");
        const business = await fetch(
          `https://${process.env.VUE_APP_API_URL}/business`,
          {
            method: "POST",
            referrerPolicy: "no-referrer",
            headers: new Headers({
              Authorization: `Bearer ${this.$store.state.access_token}`,
              Accept: "application/vnd.kangaroorewards.api.v1+json;",
              "Content-Type": "application/json",
            }),
            body: JSON.stringify({
              business: {
                name: this.$store.state.business.name,
              },
              user: {
                first_name: this.$store.state.user.first_name,
                email: this.$store.state.user.email,
                phone: this.$store.state.user.phone,
              },
              lead_id: this.$store.state.business.lead_id,
            }),
          },
        );
        if (!business.ok) {
          let errorResponse = await business.json();

          console.log("Business error: ", errorResponse);
          // console.log("Details: ", errorResponse.user.email);
          let error = {
            message: errorResponse[Object.keys(errorResponse)[0]][0],
            url: errorResponse.url,
            code: errorResponse.status,
          };
          throw error;
        }

        const dataBusiness = await business.json();

        this.$store.commit("setAccessToken", {
          token: dataBusiness.data.access_token,
        });

        this.$store.commit("setApplicationKey", {
          key: dataBusiness.data.application_key,
        });

        console.log("Businesser: ", dataBusiness);

        console.log(
          "Data rules: ",
          JSON.stringify({
            reward_ratio: this.$store.state.rewardSettings.points,
            reward_milestone_amount: this.$store.state.rewardSettings.amount,
          }),
        );
        await fetch(`https://${process.env.VUE_APP_API_URL}/rules`, {
          method: "POST",
          referrerPolicy: "no-referrer",
          headers: new Headers({
            Authorization: `Bearer ${this.$store.state.access_token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Application-Key": this.$store.state.application_key,
          }),
          body: JSON.stringify({
            reward_ratio: this.$store.state.rewardSettings.points,
            reward_milestone_amount: this.$store.state.rewardSettings.amount,
          }),
        });

        let branches = await fetch(
          `https://${process.env.VUE_APP_API_URL}/branches?include=virtual_branch`,
          {
            method: "GET",
            referrerPolicy: "no-referrer",
            headers: new Headers({
              Authorization: `Bearer ${this.$store.state.access_token}`,
              Accept: "application/vnd.kangaroorewards.api.v1+json;",
              "Content-Type": "application/json",
              "X-Application-Key": this.$store.state.application_key,
            }),
          },
        );

        let dataBranches = await branches.json();

        let branchesIds = [];
        dataBranches.data.forEach((branch) => {
          branchesIds.push({ id: branch.id });
        });

        console.log("Branches: ", dataBranches);

        for (let offer of this.$store.state.offerSettings.filter(
          (off) => off.active,
        )) {
          console.log("Offer: ", offer);
          let rewardsDataBody = {};
          let preOfferDataBody = {};
          let rewardsUrl = offer.offer
            ? "/offers"
            : offer.reward
            ? "/rewards"
            : "/campaigns";
          if (offer.id === 16 && offer.type === "cashback") {
            rewardsDataBody = {
              type_id: offer.id,
              targeted_flag: 0,
              points: offer.points,
              amount: offer.value,
              redeem_for_gift_card: false,
              never_expires_flag: 1,
              available_at_branches: branchesIds,
              reward_languages: [
                {
                  language_id: 1,
                  title: `$${offer.value} discount`,
                  description: null,
                  terms_conditions: null,
                  link: null,
                },
              ],
              subtype_id: 4,
            };
          } else if (offer.id === 18 && offer.type === "percentage") {
            rewardsDataBody = {
              type_id: offer.id,
              targeted_flag: 0,
              points: offer.points,
              discount_value: offer.value,
              never_expires_flag: 1,
              available_at_branches: branchesIds,
              reward_languages: [
                {
                  language_id: 1,
                  title: `${offer.value}% off`,
                  description: null,
                  terms_conditions: null,
                  link: null,
                },
              ],
              subtype_id: 5,
            };
          } else if (offer.id === 18 && offer.type === "free_shipping") {
            let virtualBranchesIds = [];

            dataBranches.data.forEach((branch) => {
              if (branch.virtual_branch_flag === true) {
                virtualBranchesIds.push({ id: branch.id });
              }
            }),
              (rewardsDataBody = {
                type_id: offer.id,
                targeted_flag: 0,
                points: offer.points,
                never_expires_flag: 1,
                discount_value: 0,
                shipping_option: 1,
                available_at_branches: virtualBranchesIds,
                reward_languages: [
                  {
                    language_id: 1,
                    title: "Free Shipping",
                    description: null,
                    terms_conditions: null,
                    link: null,
                  },
                ],
                subtype_id: 6,
              });
          } else if (offer.id === 1 && offer.type === "multiplier") {
            rewardsDataBody = {
              type_id: offer.id,
              targeted_offer_flag: 0,
              never_expires_flag: 1,
              offer_languages: [
                {
                  language_id: 1,
                  offer_title: "Multiplier",
                },
              ],
              available_at_branches: branchesIds,
              offer_frequency_id: 3,
              multip_factor: offer.value,
              min_purchase: 0,
              max_purchase: 1000,
              freq_details: [`${offer.points}`],
              peak_from: "00:00",
              peak_to: "23:59",
              subtype_id: 3,
            };
          } else if (offer.id === 9 && offer.type === "birthday") {
            preOfferDataBody = {
              displays_in: 9,
              min_purchase: 0,
              max_purchase: 1000,
              type_id: 3,
              targeted_offer_flag: 1,
              never_expires_flag: 1,
              available_at_branches: branchesIds,
              offer_languages: [
                {
                  language_id: 1,
                  offer_title: "Birthday",
                },
              ],
              points: offer.value,

              offer_frequency_id: 4,
              subtype_id: 1,
            };
            rewardsDataBody = {
              silent: 1,
              name: "Birthday Campaign",
              send_by: 3,
              auto: true,
              targeted_customer_criteria: [
                {
                  id: 9,
                  days: 7,
                },
              ],
              email_subject: "Happy Birthday!",
            };
          } else if (offer.id === 13 && offer.type === "first_visit") {
            preOfferDataBody = {
              displays_in: 2,
              min_purchase: 0,
              max_purchase: 1000,
              type_id: 3,
              targeted_offer_flag: 1,
              never_expires_flag: 1,
              available_at_branches: branchesIds,
              offer_languages: [
                {
                  language_id: 1,
                  offer_title: "First Visit",
                },
              ],
              points: offer.value,
              offer_frequency_id: 4,
              subtype_id: 2,
            };
            rewardsDataBody = {
              silent: 1,
              name: "First Visit Campaign",
              send_by: 3,
              auto: true,
              targeted_customer_criteria: [
                {
                  id: 13,
                  days: 3,
                },
              ],
              email_subject: "First Visit Bonus Points",
            };
          }
          if (rewardsUrl === "/campaigns") {
            let preOffer = await fetch(
              `https://${process.env.VUE_APP_API_URL}/offers`,
              {
                method: "POST",
                referrerPolicy: "no-referrer-when-downgrade",
                headers: new Headers({
                  referrerPolicy: "no-referrer",
                  Authorization: `Bearer ${this.$store.state.access_token}`,
                  Accept: "application/vnd.kangaroorewards.api.v1+json;",
                  "Content-Type": "application/json",
                  "X-Application-Key": this.$store.state.application_key,
                }),
                body: JSON.stringify(preOfferDataBody),
              },
            );

            let data = await preOffer.json();

            rewardsDataBody.offers = [
              { id: data.data.id, type: "bonus_points" },
            ];

            await fetch(`https://${process.env.VUE_APP_API_URL}${rewardsUrl}`, {
              method: "POST",
              referrerPolicy: "no-referrer-when-downgrade",
              headers: new Headers({
                referrerPolicy: "no-referrer",
                Authorization: `Bearer ${this.$store.state.access_token}`,
                Accept: "application/vnd.kangaroorewards.api.v1+json;",
                "Content-Type": "application/json",
                "X-Application-Key": this.$store.state.application_key,
              }),
              body: JSON.stringify(rewardsDataBody),
            });
          } else {
            await fetch(`https://${process.env.VUE_APP_API_URL}${rewardsUrl}`, {
              method: "POST",
              referrerPolicy: "no-referrer-when-downgrade",
              headers: new Headers({
                referrerPolicy: "no-referrer",
                Authorization: `Bearer ${this.$store.state.access_token}`,
                Accept: "application/vnd.kangaroorewards.api.v1+json;",
                "Content-Type": "application/json",
                "X-Application-Key": this.$store.state.application_key,
              }),
              body: JSON.stringify(rewardsDataBody),
            });
          }
        }

        await fetch(
          `https://${process.env.VUE_APP_API_URL}/referral-programs`,
          {
            method: "POST",
            referrerPolicy: "no-referrer-when-downgrade",
            headers: new Headers({
              Authorization: `Bearer ${this.$store.state.access_token}`,
              Accept: "application/vnd.kangaroorewards.api.v1+json;",
              "Content-Type": "application/json",
              "X-Application-Key": this.$store.state.application_key,
            }),
            body: JSON.stringify({
              referer_earns: this.$store.state.referralSettings.value1,
              referee_earns: this.$store.state.referralSettings.value2,
              expires_in: this.$store.state.referralSettings.value3,
              cond_min_amount: 0,
            }),
          },
        );

        await fetch(`https://${process.env.VUE_APP_API_URL}/widget-settings`, {
          method: "PATCH",
          referrerPolicy: "no-referrer",
          headers: new Headers({
            Authorization: `Bearer ${this.$store.state.access_token}`,
            "X-Application-Key": this.$store.state.application_key,
            Accept: "application/vnd.kangaroorewards.api.v1+json;",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            integration_id: this.getIntegrationId(
              this.$store.state.platform_id,
            ),
            widget_version: "2.0",
            widget_theme_id: this.$store.state.selectedThemeWid,
            program_name: this.$store.state.widgetTitle,
            primary_color: this.$store.state.widgetPrimaryColor,
            secondary_color: this.$store.state.widgetSecondaryColor,
          }),
        });

        if (dataBusiness.data.package_id != null) {
          window.location.href = dataBusiness.data.redirect_url;
        } else {
          this.$store.commit("setRedirectUrl", {
            n: dataBusiness.data.redirect_url,
          });
          this.$router.push({
            name: "Packages subscription",
            params: { redirect_url: dataBusiness.data.redirect_url },
          });
        }

        this.$store.commit("resetBusinessLoader");
      } catch (error) {
        this.$store.commit("resetBusinessLoader");
        this.$store.commit("setDataError", {
          error: { message: error.message },
        });
        console.log("Error: " + error);
      }
    },
  },
};
</script>
<style scoped>
.button_shadow {
  box-shadow: 0px 20px 50px #75bdd133;
}

.loader_krssu {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #2eafd3;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
</style>
