<template>
  <div class="step_card_krsuu flex h-32 sm:h-24 max-w-3xl mt-9 md:mt-12">
    <div class="flex items-center h-full my-auto float-left">
      <div class="step_icon_krssu h-16 w-16 ml-7">
        <img :src="getImgUrl(pic)" />
      </div>
      <div
        class="
          text-lg text-left text-sblue-400
          font-semibold
          break-words
          flex-shrink-2
          ml-7
        "
      >
        {{ text }}
      </div>
    </div>
    <div
      v-html="
        status
          ? getSvg(svgSettings.color, 46, 46, 'done')
          : getSvg(
              svgSettings.color,
              svgSettings.width,
              svgSettings.height,
              svgSettings.key
            )
      "
      class="float-right ml-auto h-full flex items-center"
      style="margin-right: 45.01px"
    ></div>
  </div>
</template>

<script>
import { getSvg } from "../utils/getSvg";

export default {
  name: "HorizontalCard",
  props: {
    text: { type: String },
    svgSettings: { type: Object },
    pic: { type: String },
    status: { type: Boolean },
  },
  methods: {
    getSvg: getSvg,
    getImgUrl(step) {
      var images = require.context("../assets/icons", false, /\.png$/);
      return images("./" + step + ".png");
    },
  },
};
</script>

<style scoped>
.step_card_krsuu {
  box-shadow: 0px 10px 20px #75bdd126;
  border-radius: 20px;
  opacity: 1;
}

.step_icon_krssu {
  flex-shrink: 0;
}

.step_description_krssu {
  text-align: left;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0px;
  color: #2eafd3;
  opacity: 1;
}
</style>
